<template>
  <b-modal
    :visible="show"
    :title="title"
    size="xl"
    ok-only
    ok-title="Accept"
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-row style="border-bottom:1px solid #DEE1E5;"
           class="py-2"
    >
      <b-col
        v-if="hideLanguageSummaryChart === 0"
        md="6"
      >
        <b-overlay :show="isProcessing">
          <h3 class="py-1">
            {{ $t('report.language-skill') }}
          </h3>
          <language-summary-chart
            v-if="Object.entries(languageMatrixGraph).length > 0"
            :series="languageMatrixGraph.series"
            :categories="languageMatrixGraph.category"
          />
        </b-overlay>
      </b-col>
      <b-col
        v-if="hideSkillSummaryChart === 0"
        md="6"
      >
        <b-overlay :show="isProcessing">
          <h3 class="py-1">
            {{ $t('report.study-skill') }}
          </h3>
          <skill-summary-chart
            v-if="Object.entries(studyMatrixGraph).length > 0"
            :series="studyMatrixGraph.series"
            :categories="studyMatrixGraph.category"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="py-2"
           style="border-bottom:1px solid #DEE1E5;"
    >
      <b-col
        v-if="hideStudentRoleChart === 0"
        class="mt-2 text-center"
        md="6"
      >
        <student-role-chart
          :key="studentId"
          :class-id="Number(classRoom.id)"
          :student-id="Number(studentId)"
        />
      </b-col>
      <b-col
        v-if="hideStudentNoteChart === 0"
        md="6"
        class="text-center"
      >
        <student-note-chart
          :key="studentId"
          :class-id="Number(classRoom.id)"
          :student-id="Number(studentId)"
        />
      </b-col>
    </b-row>
    <b-row class="py-2">
      <b-col
        v-if="hideStudentGameReport === 0"
        md="6"
        class="mt-2"
      >
        <h3 class="py-1">
          {{ $t('report.game-report') }}
        </h3>
        <student-game-report
          :class-room="classRoom"
          :student-id="Number(studentId)"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import {
  BModal, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import SkillSummaryChart from '@/views/common/Charts/SkillSummaryChart.vue'
import LanguageSummaryChart from '@/views/common/Charts/LanguageSummaryChart.vue'
import StudentRoleChart from '@/views/v2/common/class/components/charts/StudentRoleChart.vue'
import StudentNoteChart from '@/views/v2/common/class/components/charts/StudentNoteChart.vue'
import StudentGameReport from '@/views/common/class/game-report/index.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    SkillSummaryChart,
    BOverlay,
    LanguageSummaryChart,
    StudentRoleChart,
    StudentNoteChart,
    StudentGameReport,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
      default: 0,
    },
    classRoom: {
      type: Object,
      default: () => null,
    },
    studentInfo: {
      type: Object,
      default: () => null,
    },
    hideLanguageSummaryChart: {
      type: Number,
      default: 0,
    },
    hideSkillSummaryChart: {
      type: Number,
      default: 0,
    },
    hideStudentRoleChart: {
      type: Number,
      default: 0,
    },
    hideStudentNoteChart: {
      type: Number,
      default: 0,
    },
    hideStudentGameReport: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      languageMatrixGraph: {},
      socialMatrixGraph: {},
      studyMatrixGraph: {},
      isProcessing: false,
    }
  },
  computed: {
    getChartData() {
      return {}
    },
    title() {
      return `${i18n.tc('report.skill-chart-for')} - ${this.studentInfo?.fullname}`
    },
  },
  watch: {
    studentId: {
      async handler(id) {
        if (id) {
          this.chartGraphData = {}
          this.isProcessing = true
          await this.getClassroomSkillChart()
          await this.getClassroomSkillChartForStudy()
          this.isProcessing = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getClassroomSkillChart() {
      await useJwt
        .getClassroomSkillChart(this.classRoom.id, this.studentId)
        .then(response => {
          const dataWithScores = response.data?.data.map(score => ({
            ...score,
            percent: Math.round((score.pass / score.total) * 100, 2),
          }))
          this.languageMatrixGraph = this.drawChart(dataWithScores)
        })
        .catch(error => {
          console.error(error)
          this.showErrorMessage(error)
          this.languageMatrixGraph = {}
          this.socialMatrixGraph = {}
          this.studyMatrixGraph = {}
        })
    },
    async getClassroomSkillChartForSocial() {
      await useJwt
        .getClassroomSkillChartForSocial(this.studentId)
        .then(response => {
          const total = response.data?.data?.total
          const data = response.data?.data?.data
          const dataWithScores = data.map(score => ({
            name: score.category,
            percent: Math.round((score.earning / total) * 100, 2),
          }))
          this.socialMatrixGraph = this.drawChart(dataWithScores)
        })
    },
    async getClassroomSkillChartForStudy() {
      await useJwt.getClassroomSkillChartForStudy(this.classRoom.id, this.studentId).then(response => {
        const data = response.data?.data
        const dataWithScores = []
        Object.entries(data).forEach(([key, value]) => {
          const percent = value?.total ? Math.round((value.score / value.total) * 100, 2) : 0
          dataWithScores.push({
            name: key,
            percent,
          })
        })
        this.studyMatrixGraph = this.drawChart(dataWithScores)
      })
    },
    drawChart(dataWithScores) {
      dataWithScores.sort((a, b) => a.percent - b.percent)
      const category = dataWithScores.map(chart => this.capitalizeFirstLetter(chart.name))
      const series = [
        {
          name: 'Score',
          data: dataWithScores.map(chart => chart.percent),
        },
      ]
      return {
        category,
        series,
      }
    },
  },
}
</script>
