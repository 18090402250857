<template>
  <div>
    <h4>{{ $t('report.student-comparative-role-stats') }}</h4>
    <vue-apex-charts
      v-if="series.length"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    studentId: {
      type: Number,
      default: 0,
    },
    classId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const categories = ['read', 'conductor', 'answer']
    return {
      categories,
      series: [],
      chartOptions: {
        colors: this.getColors(),
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: categories.map(cat => this.capitalizeFirstLetter(cat)),
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter(val) {
              return `${val}`
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      },
    }
  },
  created() {
    if (this.studentId) {
      useJwt.getStudentRoleStat({
        userId: this.studentId,
        classId: this.classId,
      }).then(response => {
        const { data } = response.data
        const requiredData = []
        this.categories.forEach(category => {
          const score = data.scoreboard.reduce((p, c) => Number(c[category]) + p, 0)
          const maxValue = data.scoreboard_stat[`max_${category}`]
          const percentage = ((score / maxValue) * 100)
          requiredData.push(percentage.toFixed(2))
        })
        requiredData.sort((a, b) => b - a)
        this.series = [
          {
            name: 'Student Role',
            data: requiredData,
          },
        ]
      })
    }
  },
  methods: {
    getColors() {
      return ['#4CAF4F', '#FFEB3A', '#F44336']
    },
  },
}
</script>
